import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { Token } from 'app/interfaces/token';
import { Usuario } from 'app/interfaces/usuario';
import { SessionStorageService } from 'app/utils/session-storage.service';
import { NgxPermissionsService } from 'ngx-permissions';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  _userLogin: Usuario;
  _token = '' as string;
  isLoading$: any;

  constructor(
    private _sessionStorageService: SessionStorageService,
    private _permissionsService: NgxPermissionsService
  ) { }

  /**
   * Get token from session;
   */
  getToken() {
    try {
      this._token = JSON.parse(this._sessionStorageService.getItem('token'));
    } catch (error) {
      console.info(error);
      this._token = null;
    }
    if (this._token == null) {
      return '';
    } else {
      return this._token;
    }
  }

  /**
   * Check if user is authenticated;
   */
  public isAuthenticated(): boolean {
    try {
      this._userLogin = JSON.parse(this._sessionStorageService.getItem('user'));
      this._token = JSON.parse(this._sessionStorageService.getItem('token'));
    } catch (error) {
      this._userLogin = null;
    }

    // check if user and token is not null;
    if (this._userLogin != null && this._token != null) {
      // get token informations;
      const tokenInformation = Number(this.getTokenUserId(this._token));

      // check if session storage has been modified;
      if (this._userLogin.usuId !== tokenInformation) {
        sessionStorage.clear();
        return false;
      }

      // check if token has experied;
      return this.isTokenExpired(this._token);
    }
    return false;
  }

  /**
   *
   * @param token
   */
  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = helper.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return date.valueOf() > new Date().valueOf();
  }

  /**
   * get token informations;
   */
  getTokenUserId(token?: string): any {
    try {
      const decodedToken = helper.decodeToken(token);
      return decodedToken.id;
    } catch (error) {
      return null;
    }

  }

  /**
   * Busca os recursos que estão no token do usuário;
   * @param token 
   * @returns 
   */
  getTokenRecursos(): any {
    const token = this.getToken();
    if (this.isAuthenticated()) {
      const decodedToken = helper.decodeToken(token);
      return decodedToken.recursos;
    }
  }

  /**
 * Busca as permissões que estão no token do usuário;
 * @param token 
 * @returns 
 */
  getTokenPermissoes(): any {
    const token = this.getToken();
    if (this.isAuthenticated()) {
      const decodedToken = helper.decodeToken(token);
      return decodedToken.permissoes;
    }
  }

  getOrgId(): number {
    if (this.isAuthenticated()) {
      const token = this.getToken();
      const decodedToken = helper.decodeToken(token);
      return decodedToken.orgId;
    } else {
      throw Error('Usuário não autenticado.');
    }
  }

  getParceiroNegocioId(): number {
    if (this.isAuthenticated()) {
      const token = this.getToken();
      const decodedToken = helper.decodeToken(token);
      return decodedToken.parceiroNegocioId;
    } else {
      throw Error('Usuário não autenticado.');
    }
  }

  getUserId(): number {
    if (this.isAuthenticated()) {
      const token = this.getToken();
      const decodedToken = helper.decodeToken(token);
      return decodedToken.id;
    } else {
      throw Error('Usuário não autenticado.');
    }
  }

  getUserRole(): string[] {
    if (this.isAuthenticated()) {
      const token = this.getToken();
      const decodedToken = helper.decodeToken(token);
      return decodedToken.roles;
    } else {
      throw Error('Usuário não autenticado.');
    }
  }

  getUserPermissions() {
    const permissoes = this.getTokenPermissoes();
    if (this.isAuthenticated() && permissoes) {
      try {
        this._permissionsService.loadPermissions(permissoes);
      } catch (err) {
        sessionStorage.clear();
      }
    }
  }

  hasPerfil(nomePerfil: string) {
    return this.getUserRole().some(p => p === nomePerfil);
  }

  hasPerfilIn(nomePerfis: string[]) {
    for (const valor of this.getUserRole()) {
      if (nomePerfis.includes(valor)) {
        return true;
      }
    }
    return false;
  }

  /**
* Busca os produtos que estão no token do usuário;
* @param token 
* @returns 
*/
  getTokenProdutos(): Produto[] {
    const token = this.getToken();
    if (this.isAuthenticated()) {
      const decodedToken = helper.decodeToken(token);
      return decodedToken.produtos;
    }
  }
}
