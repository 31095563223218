import { Injectable } from '@angular/core';
import { auditObjetoAlterado } from 'app/interfaces/audit-objeto-alterado';
import { Delta, DiffPatcher } from 'jsondiffpatch';

@Injectable()
export class ComparaJsonAuditoriaService {
  anonymizationDiffDbDictionary: { [id: string]: Delta } = {};
  jsondiffpatch = new DiffPatcher();
  anonymizationGapDiff: Delta;
  partnerAnonymized2: any;
  partnerAnoPatched: any;

  constructor() {}

  /**
   *
   * @param objetoAntigo
   * @param ObjetoNovo
   * @param entidadeId
   * @returns
   */
  comparaObjetos(objetoAntigo: any, ObjetoNovo: any, entidadeId) {
    let auditObjetosAlterados = [] as auditObjetoAlterado[];

    if (!objetoAntigo) {
      objetoAntigo = {};
    }

    const delta = this.jsondiffpatch.diff(objetoAntigo, ObjetoNovo);
    this.anonymizationDiffDbDictionary[entidadeId] = delta;
    const objectDiff = this.anonymizationDiffDbDictionary[entidadeId];
    auditObjetosAlterados = this._buscaLinhasAlteradas(objectDiff, auditObjetosAlterados, objetoAntigo);

    return auditObjetosAlterados.filter(
      a => a.nomeAtributo !== 'rev' && a.nomeAtributo !== 'auditCustomId' && a.nomeAtributo !== 'revType' && a.nomeAtributo !== 'dataModificacao' && a.nomeAtributo !== 'customAuditoria'
    );
  }

  /**
   *
   * @param objectDiff
   * @param auditObjetosAlterados
   * @returns
   */
  private _buscaLinhasAlteradas(objectDiff: any, auditObjetosAlterados: auditObjetoAlterado[], objetoAntigo: any) {
    Object.keys(objectDiff).forEach(key => {
      if (typeof objectDiff[key] === 'object' && !Array.isArray(objectDiff[key])) {
        this._buscaLinhasAlteradas(objectDiff[key], auditObjetosAlterados, objetoAntigo);
      } else {
        if (objectDiff[key].length === 1) {
          if (!objetoAntigo.revType) {
            auditObjetosAlterados.push({ nomeAtributo: key, oldValue: null, newValue: this.pushValorObjeto(objectDiff[key][0]) });
          } else {
            auditObjetosAlterados.push({ nomeAtributo: key, oldValue: this.pushValorObjeto(objectDiff[key][0]), newValue: null });
          }
        } else if (objectDiff[key].length === 3) {
          auditObjetosAlterados.push({ nomeAtributo: key, oldValue: null, newValue: this.pushValorObjeto(objectDiff[key][0]) });
        } else {
          auditObjetosAlterados.push({
            nomeAtributo: key,
            oldValue: this.pushValorObjeto(objectDiff[key][0]),
            newValue: this.pushValorObjeto(objectDiff[key][1]),
          });
        }
      }
    });

    return auditObjetosAlterados;
  }

  /**
   *
   * @param object
   * @returns
   */
  private pushValorObjeto(object: any) {
    if (typeof object === 'object') {
      return object.descricao || object.sigla || object.nome;
    } else {
      return object;
    }
  }

  anonymizeNonAnonymizedAttributes() {
    this.anonymizeRecursive(this.anonymizationGapDiff, this.partnerAnoPatched);
  }

  anonymizeRecursive(diff: Delta, obj: Object) {
    if (typeof obj == 'object') {
      for (let key of Object.keys(diff)) {
        console.log('key', key, diff[key]._t);
        if (diff[key]._t === 'a') {
          for (let i in diff[key]) {
            // if (i !== "_t") {
            //   console.log("array item", i, diff[key][i][0]);
            //   console.log("obj", obj[key][i]);
            // }
            this.anonymizeRecursive(diff[key], obj[key][i]);
          }
        } else if (typeof diff[key] === 'object') {
          console.log('anonymize', typeof diff[key], diff[key][0]);
          this.anonymizeRecursive(diff[key], obj[key]);
        }
      }
    }
  }
}
