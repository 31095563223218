import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Grupo } from 'app/interfaces/grupo';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { TimezoneService } from 'app/utils/timezone.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GrupoHttpService {
  constructor(private _http: HttpClient, private _timezoneService: TimezoneService) {}

  /**
   * Função para buscar todos grupos;
   * @param nome
   * @param page
   * @returns
   */
  getAll(nome = '', page = 0) {
    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/grupos?nome=${nome}&page=${page}`);
  }

  /**
   * Função para buscar todos grupos pelo nome;
   * @param nome
   * @param page
   * @returns
   */
  getByNome(nome = '') {
    return this._http.get<Grupo[]>(`${environment.apiUrl}/api/authentication/v1/grupos/by-nome?nome=${nome}`);
  }

  /**
   * Função para buscar todos grupos em hierarquia ;
   * @param nome
   * @param page
   * @returns
   */
  getAllHierarquico(nome = '') {
    return this._http.get<Grupo[]>(`${environment.apiUrl}/api/authentication/v1/grupos/hierarquico?nome=${nome}`);
  }

  /**
   * Função para buscar a grupo pelo id;
   * @param id
   * @returns
   */
  getById(id: number) {
    return this._http.get<Grupo>(`${environment.apiUrl}/api/authentication/v1/grupos/${id}`);
  }

  /**
   * Função para salvar uma grupo;
   * @param grupo
   * @returns
   */
  salvar(grupo: Grupo) {
    return this._http.post<Grupo>(`${environment.apiUrl}/api/authentication/v1/grupos`, grupo);
  }

  /**
   * Função para atualizar uma grupo;
   * @param grupo
   * @returns
   */
  update(grupo: Grupo) {
    return this._http.put<Grupo>(`${environment.apiUrl}/api/authentication/v1/grupos/${grupo.grpId}`, grupo);
  }

  /**
   * Delete um registro;
   * @param id
   * @returns
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/authentication/v1/grupos/${id}`);
  }

  /**
   * Função para buscar somente os grupos (limpo de dependencias)
   * @returns
   */
  getGrupos() {
    return this._http.get<Grupo[]>(`${environment.apiUrl}/api/authentication/v1/grupos/all`);
  }

  getAllAudit(grpId: number, page: number, email: string, dataInicial: Date, dataFinal: Date, tipoAuditoria: string) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/grupos-auditorias/${grpId}`, { params: params });
  }

  getAuditComparacao(grpId: number, rev: number) {
    return this._http.get<any[]>(`${environment.apiUrl}/api/authentication/v1/grupos-auditorias/comparacao-audit/${grpId}?rev=${rev}`);
  }
}
