import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { Anexo } from 'app/interfaces/anexo';
import { Email } from 'app/interfaces/email.model';
import { TipoEmailOportunidadeOferta } from 'app/interfaces/enum/tipo-email-oportunidade-oferta';
import { Usuario } from 'app/interfaces/usuario';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailHttpService {

  constructor(
    private _http: HttpClient
  ) { }


  resetSenha(email: string) {
    return this._http.get<Usuario>(`${environment.apiUrl}/auth/email/?email=${email}`);
  }

  /**
  * Função para buscar todos emails;
  * @param page 
  * @param sort
  * @param size
  * @param processoId
  * @param oportunidadeId
  * @param parceiroNegocioId
  * @param contaId
  * @param tarifarioId
  * @param tarifarioLocalId
  * @returns 
  */
  getAll(
    { page = 0, sort = "", size = null, filtro = "", processoId, oportunidadeId, parceiroNegocioId, contaId, tarifarioId, tarifarioLocalId, faturaId }
    : { page?: number, sort?: string, size?: number, filtro?: string, processoId?: number, oportunidadeId?: number, parceiroNegocioId?: number, contaId?: number, tarifarioId?: number, tarifarioLocalId?: number, faturaId? : number }) {    
    let params = {
      page: page,
      size: size ? size : '',
      sort: sort,
      filtro: filtro,
      processoId: processoId ? processoId : "",
      oportunidadeId: oportunidadeId ? oportunidadeId : "",
      parceiroNegocioId: parceiroNegocioId ? parceiroNegocioId : "",
      contaId: contaId ? contaId : "",
      tarifarioId: tarifarioId ? tarifarioId : "",
      tarifarioLocalId: tarifarioLocalId ? tarifarioLocalId : "",
      faturaId: faturaId ? faturaId : "",
    }
    return this._http.get<Pageable>(
      `${environment.apiUrl}/api/email/v1/emails`, { params: params }
    )
  }

  /**
   * Função para buscar um email pelo id;
   * @param id 
   * @returns 
   */
  getById(id: string) {
    return this._http.get<Email>(`${environment.apiUrl}/api/email/v1/emails/${id}`);
  }

  getAssinatura() {
    return this._http.get(`${environment.apiUrl}/api/email/v1/emails/assinatura`);
  }

  /**
 * Função para salvar um email;
 * @param email
 * @returns 
 */
  uploadMsg(formData: FormData, entidadeId?: { oportunidadeId?: number, parceiroNegocioId?: number, contaId?: number, tarifarioId?: number, processoId?: number}) {
    const params = entidadeId;
    return this._http.post<Email>(`${environment.apiUrl}/api/email/v1/emails/upload-msg`, formData, { params: params });
  }

  /**
   * Função para salvar um email;
   * @param email
   * @returns 
   */
  enviarEmail(formData: FormData) {
    return this._http.post<Email>(`${environment.apiUrl}/api/email/v1/emails/enviar`, formData);
  }

  /**
 * Função para salvar um email;
 * @param anexo
 * @returns 
 */
  downloadAnexo(emailId: string, anexoId: number) {
    const params = {
      anexoId: anexoId
    }
    return this._http.get<Anexo>(`${environment.apiUrl}/api/email/v1/emails/download-anexo/${emailId}`, { params: params });
  }

  /**
   * Delete um registro;
   * @param id 
   * @returns 
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/email/v1/emails/${id}`);
  }

}
