import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../main/pages/authentication/auth.service';
import { AgendaHttpService } from 'app/main/agenda/agenda-http.service';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'app/utils/toastr-custom/message.service';

@Injectable()
export class AtividadesAtrasadasService {

  constructor(
    private auth: AuthService,
    private router: Router,
    private _agendaHttpService: AgendaHttpService,
    private _messageService: MessageService) { }

  async verificaAtividadesAtrasadas(): Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      let qtdeAtrasadas = await this._buscaAtividadesAtrasadas();
      if (qtdeAtrasadas === 0) {
        return true;
      } else {
        this._messageService.warning("Atenção", "Existem atividades atrasadas, conclua-as para navegar no sistema.")
        this.router.navigate(['/todo']);
        return false;
      }
    }
    this.router.navigate(['/auth/login']);
    return false;
  }


  /**
  * Busca pelas atividades atrasadas do usuário;
  * @returns 
  */
  private async _buscaAtividadesAtrasadas() {
    try {
      return await firstValueFrom(this._agendaHttpService.verificaAtividadesAtrasadas());
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
}
