import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  // private
  private toastRef: any;
  private options: GlobalConfig;

  /**
   * Constructor
   *
   * @param {ToastrService} toastr
   */
  constructor(private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  // Success
  success(title: string, body: string) {
    this.toastr.success(body, title, {
      positionClass: 'toast-top-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Info
  info(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-top-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Warning
  warning(title: string, body: string) {
    this.toastr.warning(body, title, {
      positionClass: 'toast-top-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Error
  error(title: string, body: string) {
    this.toastr.error(body, title, {
      positionClass: 'toast-top-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Top Left
  toastrTopLeft(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-top-left',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Top Center
  toastrTopCenter(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-top-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Top Right
  toastrTopRight(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-top-right',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Top Fullwidth
  toastrTopFullWidth(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-top-full-width',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Bottom Left
  toastrBottomLeft(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-bottom-left',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Bottom Center
  toastrBottomCenter(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-bottom-center',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Bottom Right
  toastrBottomRight(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-bottom-right',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Bottom Fullwidth
  toastrBottomFullWidth(title: string, body: string) {
    this.toastr.info(body, title, {
      positionClass: 'toast-bottom-full-width',
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Progress Bar
  toastrProgressBar(title: string, body: string) {
    this.toastr.success(body, title, {
      progressBar: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Clear Toast Button
  toastrClearToastButton(title: string, body: string) {
    this.toastr.info(body, title, {
      enableHtml: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Rshow
  toastrRShow(title: string, body: string) {
    this.toastRef = this.toastr.info(body, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Remove
  toastrRemove(title: string, body: string) {
    this.toastr.remove(this.toastRef.toastId);
  }

  // Cshow
  toastrCShow(title: string, body: string) {
    this.toastr.info(body, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Clear
  toastrClear() {
    this.toastr.clear();
  }

  // Ease Time
  toastrEaseTime(title: string, body: string) {
    this.toastr.success(body, title, {
      easeTime: 1000,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Timeout 3sec
  toastrTimeout3(title: string, body: string) {
    this.toastr.warning(body, title, {
      timeOut: 3000,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Timeout 5sec
  toastrTimeout5(title: string, body: string) {
    this.toastr.error(body, title, {
      timeOut: 5000,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

  // Stickey
  toastrStickey(title: string, body: string) {
    this.toastr.info(body, title, {
      timeOut: 0,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }
}
