import { Entidade } from 'app/interfaces/enum/entidade.enum';
import { environment } from 'environments/environment';
import { Atividade } from 'app/interfaces/atividade';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { StatusAntivirus } from 'app/interfaces/enum/status-antivirus';
import { StatusRequisito } from 'app/interfaces/enum/status-requisito.enum';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { FaturaStatus } from 'app/interfaces/enum/fatura-status.enum';

@Injectable({
  providedIn: 'root'
})
export class AgendaHttpService {

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * FIXME:
 * Retorna todas as atividades
 * Backend configurado para retornar todas as atividade do usuário cujo fez a requisição - (14/03/23)
 */

  /**
   * 
   * @param entidade
   * @param dataInicial
   * @param dataFinal
   * @param entidade
   * @param concluido
   * @returns 
   */
  buscaAtividades(
    {
      entidade,
      responsavelId,
      dataInicial,
      dataFinal,
      tipoAtividade = '',
      concluido,
      parceiroNegocioId,
      contaId,
      tarifarioId,
      oportunidadeId,
      faturaId,
      processoId,
      filtro,
      grupoId,
      sort,
    }:
      {
        entidade: Entidade,
        responsavelId?: string,
        dataInicial: string,
        dataFinal: string,
        tipoAtividade?: string,
        filtro?: string,
        concluido?: boolean,
        parceiroNegocioId?: number,
        contaId?: number,
        tarifarioId?: number,
        oportunidadeId?: number,
        faturaId?: number,
        processoId?: number,
        grupoId?: number,
        sort?: string
      }) {

    let params = {
      entidade: entidade,
      responsavelId: responsavelId ? responsavelId : '',
      dataInicial: dataInicial,
      dataFinal: dataFinal,
      tipoAtividade: tipoAtividade,
      concluido: concluido,
      filtro: filtro ? filtro : '',
      parceiroNegocioId: parceiroNegocioId ? parceiroNegocioId : '',
      contaId: contaId ? contaId : '',
      tarifarioId: tarifarioId ? tarifarioId : '',
      oportunidadeId: oportunidadeId ? oportunidadeId : '',
      faturaId: faturaId ? faturaId : '',
      processoId: processoId ? processoId : '',
      grupoId: grupoId ? grupoId : '',
      sort: sort
    }

    return this._http.get<Pageable>(`${environment.apiUrl}/api/agenda/v1/atividades/calendario`, { params: params });
  }

  buscaUltimasAtividades({ parceiroNegocioId, tarifarioId, oportunidadeId, faturaId, entidade, processoId }:
    {
      parceiroNegocioId?: number,
      tarifarioId?: number,
      oportunidadeId?: number,
      faturaId?: number,
      processoId?: number,
      entidade: Entidade
    }) {

    let params = {
      parceiroNegocioId: parceiroNegocioId ? parceiroNegocioId : '',
      tarifarioId: tarifarioId ? tarifarioId : '',
      oportunidadeId: oportunidadeId ? oportunidadeId : '',
      faturaId: faturaId ? faturaId : '',
      processoId: processoId ? processoId : '',
      entidade: entidade
    }

    return this._http.get<Atividade[]>(`${environment.apiUrl}/api/agenda/v1/atividades/ultimas-atividades`, { params: params });
  }

  /**
   * Busca por Id da atividade ou/e parceiro de negocio;
   * @param atividadeId 
   * @returns 
   */
  getAtividadeById({ atividadeId, responsavelId }:
    {
      atividadeId: String,
      responsavelId?: number
    }) {

    let params = {
      responsavelId: responsavelId ? responsavelId : ''
    }

    return this._http.get<Atividade>(`${environment.apiUrl}/api/agenda/v1/atividades/${atividadeId}`, { params: params });
  }

  /**
   * Salva uma nova entidade;
   * @param param0 
   * @returns 
   */
  salvaAtividade({ parceiroNegocioId, contaId, tarifarioId, oportunidadeId, processoId, faturaId, atividade, entidade, responsavelId }:
    {
      atividade: Atividade,
      parceiroNegocioId?: number,
      contaId?: number,
      tarifarioId?: number,
      oportunidadeId?: number,
      processoId?: number,
      faturaId?: number,
      entidade: Entidade,
      responsavelId?: number
    }) {

    let params = {
      parceiroNegocioId: parceiroNegocioId ? parceiroNegocioId : '',
      tarifarioId: tarifarioId ? tarifarioId : '',
      contaId: contaId ? contaId : '',
      oportunidadeId: oportunidadeId ? oportunidadeId : '',
      processoId: processoId ? processoId : '',
      faturaId: faturaId ? faturaId : '',
      responsavelId: responsavelId ? responsavelId : '',
      entidade: entidade
    }

    return this._http.post<Atividade>(`${environment.apiUrl}/api/agenda/v1/atividades`, atividade, { params: params });
  }



  /**
  * 
  * @param atividade 
  * @returns 
  */
  updateAtividade({ atividade, responsavelId: responsavelId }: { atividade: Atividade, responsavelId?: number }) {

    const params = {
      responsavelId: responsavelId ? responsavelId : ''
    }

    return this._http.put<Atividade>(`${environment.apiUrl}/api/agenda/v1/atividades/${atividade.atividadeId}`, atividade, { params });
  }

    /**
  * 
  * @param atividade 
  * @returns 
  */
    estornaAtividade(atividade: Atividade) {
      return this._http.put<Atividade>(`${environment.apiUrl}/api/agenda/v1/atividades/estornar`, atividade);
    }


  /**
  * 
  * @param atividade 
  * @returns 
  */
  marcarComoConcluidoByIds(atividadeIds: String[]) {
    return this._http.put<boolean>(`${environment.apiUrl}/api/agenda/v1/atividades/concluir`, atividadeIds);
  }

  /**
   * 
   * @param atividade 
   * @returns 
   */
  deleteAtividade(atividade: Atividade) {
    const params = {
      responsavelId: atividade.responsavelId
    }
    return this._http.delete<boolean>(`${environment.apiUrl}/api/agenda/v1/atividades/${atividade.atividadeId}`, { params });
  }

  /**
   * 
   * @param atividades 
   * @returns 
   */
  updateAtividadeLote(atividades: Atividade[]) {
    return this._http.patch<Atividade>(`${environment.apiUrl}/api/agenda/v1/atividades`, atividades);
  }

  /**
   * Verifica se o usuário possui alguma atividade atrasada;
   * @returns 
   */
  verificaAtividadesAtrasadas() {
    return this._http.get<number>(`${environment.apiUrl}/api/agenda/v1/atividades/verifica-atividades-atrasadas`);
  }


  criarAtividadeAvisoRequisitoAprovadoOuReprovado(atividadeOrigemId: string, justificativa: string, status: StatusRequisito, produto: Produto) {
    return this._http.put<boolean>(`${environment.apiUrl}/api/agenda/v1/atividades/cria-atividade-requisito-reprovado-aprovado?atividadeOrigemId=${atividadeOrigemId}&justificativa=${justificativa}&status=${status}&produto=${produto}`, null);
  }

  /**
   * Método para criar uma atividade para avisar se a fatura foi aprovada ou recusada;
   * @param atividadeOrigemId 
   * @param justificativa 
   * @param status 
   * @returns 
   */
  criarAtividadeAvisoFaturaAprovadoOuReprovado(atividadeOrigemId: string, justificativa: string, status: FaturaStatus) {
    return this._http.put<boolean>(`${environment.apiUrl}/api/agenda/v1/atividades/cria-atividade-fatura-reprovado-aprovado?atividadeOrigemId=${atividadeOrigemId}&justificativa=${justificativa}&status=${status}`, null);
  }

    /**
   * Método para criar uma atividade para avisar se a oferta foi aprovada ou recusada;
   * 
   * @param atividadeOrigemId 
   * @param status 
   * @returns 
   */
  
    criarAtividadeAvisoOfertaAprovadoOuReprovado(atividadeOrigemId: string, status: boolean) {
      return this._http.put<boolean>(`${environment.apiUrl}/api/agenda/v1/atividades/cria-atividade-oferta-reprovado-aprovado?atividadeOrigemId=${atividadeOrigemId}&status=${status}`, null);
    }

}
