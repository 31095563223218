import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'statusRequisito'
})
export class StatusRequisitoPipe implements PipeTransform {
    transform(revType: string): string {
        let statusRequisito = '';
        switch (revType) {
            case 'AA':
                statusRequisito = 'Aguardando Aprovação';
                break;
            case 'PA':
                statusRequisito = 'Parcialmente Aprovado';
                break;
            case 'A':
                statusRequisito = 'Aprovado';
                break;
            case 'R':
                statusRequisito = 'Reprovado';
                break;
            default:
                break;
        }
        return statusRequisito;
    }
}