import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

// Diretiva que permite números
@Directive({
  selector: '[normalizeWithNumbers]'
})
export class NormalizeWithNumbersDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const transformed = input.value.toUpperCase().replace(/[^A-Z0-9\s!@#$%^&*(),.?"':{}_|<>-]/g, '');
    this.control.control?.setValue(transformed);
  }
}

// Diretiva que não permite números
@Directive({
  selector: '[normalizeWithoutNumbers]'
})
export class NormalizeWithoutNumbersDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const transformed = input.value.toUpperCase().replace(/[^A-Z\s]/g, '');
    this.control.control?.setValue(transformed);
  }
}

@Directive({
  selector: '[onlynumbers]'
})
export class OnlyNumbersDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    // Remove todos os caracteres que não são números
    const transformed = input.value.replace(/[^0-9]/g, '');
    this.control.control?.setValue(transformed);
  }
}