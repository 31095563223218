import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { ScreenHeadersModule } from 'app/layout/components/screen-headers/screen-headers.module';
import { NgbAlertModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from 'app/utils/directives/directives.module';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthGuard } from '../pages/authentication/guards/auth.guards';
import { UsuarioDetailResolver } from './guards/usuario-details.resolver';
import { UsuarioResolver } from './guards/usuario-list.resolver';
import { CanDeactivateGuard } from './guards/usuario.deactivate';
import { UsuarioAddComponent } from './usuario-add/usuario-add.component';
import { UsuarioDetailsComponent } from './usuario-details/usuario-details.component';
import { UsuarioListComponent } from './usuario-list/usuario-list.component';
import { UsuarioComponent } from './usuario.component';
import { UsuarioSenhaModalComponent } from './usuario-senha-modal/usuario-senha-modal.component';
import { UsuarioLinkAddComponent } from './usuario-details/usuario-link-add/usuario-link-add.component';
import { PermissaoRecursoGuard } from 'app/utils/guards/permissao-recurso.guards';
import { ProdutoSelectModule } from 'app/layout/components/produto-select/produto-select.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UsuarioAuditComponent } from './usuario-audit/usuario-audit.component';
import { ComparaJsonAuditoriaService } from 'app/utils/compara-json-auditoria.service';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { PipesModule } from 'app/utils/pipes/pipes.module';

const routes: Routes = [
  { path: '', redirectTo: 'usuario', pathMatch: 'full' },
  {
    path: '',
    component: UsuarioComponent,
    canActivate: [AuthGuard, PermissaoRecursoGuard],
    data: { permissao: 'menu-usuario' },
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: '',
        component: UsuarioListComponent,
        pathMatch: 'full',
        resolve: { usuarios: UsuarioResolver },
      },
      {
        path: 'add',
        component: UsuarioAddComponent,
        pathMatch: 'full',
      },
      {
        path: 'details/:id',
        component: UsuarioDetailsComponent,
        pathMatch: 'full',
        resolve: { usuario: UsuarioDetailResolver },
      },
    ],
  },
];

@NgModule({
  declarations: [
    UsuarioAddComponent,
    UsuarioListComponent,
    UsuarioDetailsComponent,
    UsuarioComponent,
    UsuarioSenhaModalComponent,
    UsuarioLinkAddComponent,
    UsuarioAuditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbTooltipModule,
    NgxMaskModule.forRoot(),
    NgbPaginationModule,
    NgbAlertModule,
    NgSelectModule,
    DirectivesModule,
    PerfectScrollbarModule,
    CoreCommonModule,
    ScreenHeadersModule,
    NgbAlertModule,
    ProdutoSelectModule,
    NgxPermissionsModule,
    Ng2FlatpickrModule,
    PipesModule,
  ],
  providers: [UsuarioResolver, UsuarioDetailResolver, ComparaJsonAuditoriaService],
  exports: [UsuarioSenhaModalComponent],
})
export class UsuarioModule {}
