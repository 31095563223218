import { ToastrService } from "ngx-toastr";
import { MessageService } from "./toastr-custom/message.service";

/**
 * Handle error messages coming from the backend.
 * It tries to parse the details section and fallback to message when needed.
 *
 * @param messageService the {@link ToastrService} of the view
 * @param error the caught error
 * @param title optional title
 */
const handleErrorMessage = (messageService: MessageService, error: any, title: string = 'Atenção') => {
  const innerError = error.error;

  // check info on error object
  const hasDetails = !!innerError?.details;

  // load messages
  const details = innerError?.details;
  const message = innerError?.message;

  // raise message
  messageService.error('Atenção', hasDetails ? details : message);
}

export default handleErrorMessage;
