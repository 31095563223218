import { Injectable } from '@angular/core';
import { Pageable } from 'app/interfaces/Pageable/pageable';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  state = {
    filtro: "",
    nome: "",
    email: "",
    data: {} as Pageable
  };


  constructor(
  ) { }

  destroy() {
    this.state = {
      filtro: "",
      nome: "",
      email: "",
      data: {} as Pageable
    };
  }

}
