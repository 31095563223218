import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { UsuarioHttpService } from 'app/main/usuario/usuario-http.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsuarioService } from '../usuario.service';

@Injectable()
export class UsuarioResolver implements Resolve<Pageable> {

    constructor(
        private _usuarioService: UsuarioService,
        private _usuarioHttpService: UsuarioHttpService,
        private router: Router) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<Pageable> | Promise<Pageable> | Pageable {

        /*const extraState = this.router.getCurrentNavigation().extras.state;
        if (extraState && extraState.skipResolver) {
            
        }*/

        if (!this._usuarioService.state.data.number || this._usuarioService.state.data.number == 0) {
            return this._usuarioHttpService.getUsuarios(
                {
                    filtro: this._usuarioService.state.filtro,
                    page: this._usuarioService.state.data.number
                }
            ).pipe(catchError(err => {
                this.router.navigate(["/404"]);
                return EMPTY;
            }));
        } else {
            return this._usuarioHttpService.getUsuarios(
                {
                    filtro: this._usuarioService.state.filtro,
                    page: this._usuarioService.state.data.number
                }
            ).pipe(catchError(err => {
                this.router.navigate(["/404"]);
                return EMPTY;
            }));
        }
    }
}