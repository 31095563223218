import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioLinkBi } from 'app/interfaces/usuarioLinkBi';
import handleErrorMessage from 'app/utils/error-message-handler';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-usuario-link-add',
  templateUrl: './usuario-link-add.component.html',
  styleUrls: ['./usuario-link-add.component.scss']
})
export class UsuarioLinkAddComponent implements OnInit {

  @Input() usuarioLinkBi: UsuarioLinkBi;
  form: FormGroup;
  submitted: boolean = false;

  //tiposContainer = [] as TipoContainer[];
  tipoContainerText = new Subject<string>();

  constructor(
    private _activeModal: NgbActiveModal,
    //private _tipoContainerHttpService: TipoContainerHttpService,
    private _messageService: MessageService,
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeFilters();

    if (this.usuarioLinkBi) {
      const objetoConstruido = this.construirObjetosDoForm(this.usuarioLinkBi);
      this.form.patchValue(objetoConstruido);
    }
  }

  /**
   * Método para inicializar o formulário;
   */
  initForm() {
    this.form = this._fb.group({
      nome: [null, [Validators.required]],
      link: [null, [Validators.required]],      
      ativo: [true],
    })
  }

  subscribeFilters() {
    this.tipoContainerText.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
      .subscribe(value => {
        //this.getContainerByFilter(value);
      })
  }

  // getContainerByFilter(descricao) {
  //   this._tipoContainerHttpService.getAll(descricao).subscribe(
  //     {
  //       next: (result) => {
  //         this.tiposContainer = result.content;
  //       },
  //       error: (error) => {
  //         handleErrorMessage(this._messageService, error);
  //       }
  //     }
  //   )
  // }

  desconstruirObjetosDoForm() {
    this.usuarioLinkBi = this.form.value

    // Variável apenas para melhorar a visualização do código
    const formValue = this.form.value

    if (formValue.tipoContainer) {
      //this.usuarioLinkBi.tipoContainerId = formValue.tipoContainer.tipoContainerId;
      //this.usuarioLinkBi.tipoContainerDescricao = formValue.tipoContainer.descricao;
    } else {
      //this.usuarioLinkBi.tipoContainerId = null;
      //this.usuarioLinkBi.tipoContainerDescricao = null;
    }
  }

  construirObjetosDoForm(usuarioLinkBi) {
    
    // if (this.usuarioLinkBi?.tipoContainerId) {
    //   equipamento.tipoContainer = {
    //     tipoContainerId: this.usuarioLinkBi.tipoContainerId,
    //     descricao: this.usuarioLinkBi.tipoContainerDescricao
    //   }
    // }

    return usuarioLinkBi;
  }

  submit() {
    this.submitted = true;
    this.desconstruirObjetosDoForm();
    this._activeModal.close(this.usuarioLinkBi);
  }

  /**
   * Método para fechar o modal sem processar nada;
   */
  close() {
    this._activeModal.dismiss();
  }

  /**
   * Método para fechar o modal processando os resultados;
   * @param pais 
   */
  done(container: any) {
    this._activeModal.close(container);
  }

  get f() {
    return this.form.controls;
  }

}
