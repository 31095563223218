import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JasperFolder } from '../../interfaces/jasper/jasper-folder';
import { JasperRelatorio } from '../../interfaces/jasper/jasper-relatorio';
import { JasperBuscaResult } from 'app/interfaces/jasper/jasper-busca-result';
import { JasperGerarTemplateBody } from 'app/interfaces/jasper/jasper-gerar-template-body';
import { JasperReport } from 'app/interfaces/jasper/jasper-report';

@Injectable({
  providedIn: 'root',
})
export class JasperHttpService {

  constructor(
    private _http: HttpClient,
  ) {
  }

  /**
   * Lista todas pastas criadas no jasper;
   */
  listaPastasJasper() {
    return this._http.get<JasperFolder>(`${environment.apiUrl}/api/internal/v1/jasper/folders`);
  }

  /**
   * Lista todos relatórios de uma determinada pasta;
   * @param folderName
   */
  listaRelatorios(folderName: string) {

    const param = {
      folder: folderName
    }

    return this._http.get<JasperRelatorio>(`${environment.apiUrl}/api/internal/v1/jasper/reports`, { params: param });
  }

  findByNome(nome: string) {

    const param = {
      nome: nome
    }

    return this._http.get<JasperBuscaResult>(`${environment.apiUrl}/api/internal/v1/jasper/find-by-nome`, { params: param });
  }

  gerarTemplateJasper(body: JasperGerarTemplateBody) {
    return this._http.post<JasperReport>(`${environment.apiUrl}/api/internal/v1/jasper/gerar-pdf-jasper`, body);
  }
}
