import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';

import { ContentComponent } from 'app/layout/components/content/content.component';
import { NgProgressModule } from 'ngx-progressbar';

@NgModule({
  declarations: [ContentComponent],
  imports: [
    RouterModule, 
    CoreCommonModule,
    NgProgressModule.withConfig({
      color: "#28c76f",
      spinner: false,

    }),
  ],
  exports: [ContentComponent]
})
export class ContentModule {}
