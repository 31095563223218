import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'descricaoTipoRequisito'
})
export class DescricaoTipoRequisitoPipe implements PipeTransform {
    transform(tipoRequisito: string): string {
        let descricaoTipoRequisito = '';
        switch (tipoRequisito) {
            case 'RCD':
                descricaoTipoRequisito = 'Descrição Requisito Comercial';
                break;
            case 'ROD':
                descricaoTipoRequisito = 'Requisito Operacional Descrição';
                break;
            case 'RFD':
                descricaoTipoRequisito = 'Requisito Financeiro Descrição';
                break;
            case 'RFS':
                descricaoTipoRequisito = 'Requisito Financeiro Spread';
                break;
            case 'RFP':
                descricaoTipoRequisito = 'Requisito Financeiro Pagamento';
                break;
            case 'RFC':
                descricaoTipoRequisito = 'Requisito Financeiro Crédito';
                break;
            default:
                break;
        }
        return descricaoTipoRequisito;
    }
}