import { Injectable } from '@angular/core';
import { Recurso } from 'app/interfaces/recurso';
import { AuthService } from 'app/main/pages/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissaoRecursoService {

  recursos: string[];

  constructor(private _authService: AuthService) {
    this.recursos = this._authService.getTokenRecursos();
  }

  /**
   * Verifica se existe permissão para entrar na tela;
   * @param component 
   * @returns 
   */
  temPermissao(component: any): boolean {
    return this.verificaPermissao(component?.data?.permissao);
  }

  /**
   * Verifica se a permissão do componente também existe no token do usuário;
   * @param recurso 
   * @returns 
   */
  verificaPermissao(recurso: string) {
    return this.recursos.some(r => r.toUpperCase() === recurso.toUpperCase());
  }
}
