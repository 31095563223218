import { trigger, transition, style, query, group, animateChild, animate, keyframes, state, sequence } from '@angular/animations';

// Animation: FadeInLeft
export const fadeInLeft = trigger('fadeInLeft', [
  transition('* => *', [
    query(
      ':enter, :leave',
      style({ position: 'absolute', left: 0, width: '100%', paddingRight: '2rem', paddingLeft: '2rem', opacity: 0 }),
      {
        optional: true
      }
    ),
    query(':enter', style({ transform: 'translateX(-100%)', opacity: 0 }), { optional: true }),

    group([
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate('600ms ease', style({ opacity: 1, transform: 'translateX(100%)' }))
        ],
        { optional: true }
      ),
      query(':enter', [animate('800ms ease', style({ opacity: 1, transform: 'translateX(0%)' })), animateChild()], {
        optional: true
      })
    ])
  ])
]);

// Animation: ZoomIn
export const zoomIn = trigger('zoomIn', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          paddingRight: '2rem',
          paddingLeft: '2rem',
          opacity: 0,
          transform: 'scale(0.5) translateY(-20%)'
        })
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        animate(
          '400ms ease',
          style({ opacity: 1, paddingRight: '2rem', paddingLeft: '2rem', transform: 'scale(1) translateY(0)' })
        )
      ],
      {
        optional: true
      }
    )
  ])
]);

// Animation: FadeIn
export const fadeIn = trigger('fadeIn', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          paddingRight: '2rem',
          paddingLeft: '2rem',
          opacity: 0
        })
      ],
      { optional: true }
    ),
    query(':enter', [animate('500ms ease', style({ opacity: 1, paddingRight: '2rem', paddingLeft: '2rem' }))], {
      optional: true
    })
  ])
]);

export const grow = trigger("grow", [ // Note the trigger name
  transition(":enter", [
    // :enter is alias to 'void => *'
    style({ height: "0", overflow: "hidden" }),
    animate(500, style({ height: "*" }))
  ]),
  transition(":leave", [
    // :leave is alias to '* => void'
    animate(500, style({ height: 0, overflow: "hidden" }))
  ])
]);

export const fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),

  transition(':enter, :leave', [
    animate(200)
  ])
]);

export const bounce = trigger('bounce', [
  state('active', style({ transform: 'translateY(0)' })),
  transition(':enter, :leave', [
    sequence([
      style({ transform: 'translateY(0)' }),
      animate("400ms cubic-bezier(0,0,0,1)", style({ transform: 'translateY(-12px)' })),
      animate("300ms cubic-bezier(1,0,1,1)", style({ transform: 'translateY(0)' })),
      animate("200ms cubic-bezier(0,0,0,1)", style({ transform: 'translateY(-8px)' })),
      animate("150ms cubic-bezier(1,0,1,1)", style({ transform: 'translateY(0)' })),
      animate("100ms cubic-bezier(0,0,0,1)", style({ transform: 'translateY(-3px)' })),
      animate("80ms cubic-bezier(1,0,1,1)", style({ transform: 'translateY(0)' })),
    ]),
  ])
]);