import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PopoverService } from "../popover.service";

@Directive({
    selector: "[popoverTrigger]"
})
export class PopoverDirective implements OnDestroy, OnInit {
    @Input()
    popoverTrigger!: TemplateRef<object>;

    @Input()
    closeOnClickOutside: boolean = false;

    @Input() position: string = 'top';

    private unsubscribe = new Subject();
    private overlayRef!: OverlayRef;

    constructor(
        private elementRef: ElementRef,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        private popoverService: PopoverService
    ) { }

    ngOnInit(): void {
        this.createOverlay();
        this.popoverService.getState().subscribe(resp => {
            if (resp) {
                this.detachOverlay();
            }
        });
    }

    ngOnDestroy(): void {
        this.detachOverlay();
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    @HostListener("click") clickou() {
        this.attachOverlay();
    }

    private createOverlay(): void {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef)
            .withPositions([this.getPosition()]);

        //ToDo entender como funciona o posicionamento
        // new ConnectionPositionPair(
        //   { originX: "start", originY: "bottom" },
        //   { overlayX: "start", overlayY: "bottom" }
        // ),
        // new ConnectionPositionPair(
        //   { originX: "start", originY: "bottom" },
        //   { overlayX: "start", overlayY: "bottom" }
        // )

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: ''
        });

        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                if (this.closeOnClickOutside) {
                    this.detachOverlay();
                }
            });
    }

    private attachOverlay(): void {
        if (!this.overlayRef.hasAttached()) {
            const periodSelectorPortal = new TemplatePortal(
                this.popoverTrigger,
                this.vcr
            );

            this.overlayRef.attach(periodSelectorPortal);
        }
    }

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }

    getPosition(): any {
        switch (this.position) {
            case 'top':
                return {
                    originX: 'center',
                    originY: 'top',
                    overlayX: 'center',
                    overlayY: 'bottom',
                }
            case 'right':
                return {
                    originX: 'end',
                    originY: 'center',
                    overlayX: 'start',
                    overlayY: 'center',
                }
            case 'bottom':
                return {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                }
            case 'left':
                return {
                    originX: 'start',
                    originY: 'center',
                    overlayX: 'end',
                    overlayY: 'center',
                }
            default:
                break;
        }
    }
}
