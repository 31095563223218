import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Usuario } from 'app/interfaces/usuario';
import { UsuarioHttpService } from 'app/main/usuario/usuario-http.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UsuarioDetailResolver implements Resolve<Usuario> {

    constructor(
        private _usuarioHttpService: UsuarioHttpService,
        private _router: Router) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<Usuario> | Promise<Usuario> | Usuario {

        return this._usuarioHttpService.getUsuario(route.params.id).pipe(catchError(err => {
            this._router.navigate(["/404"]);
            return EMPTY;
        }));
    }
}