import { Component, OnInit } from '@angular/core'
import { Empresa } from 'app/interfaces/enum/empresas';
import { Usuario } from 'app/interfaces/usuario'
import { SessionStorageService } from 'app/utils/session-storage.service'
import { UsuarioHttpService } from '../usuario/usuario-http.service';
import { UsuarioLinkBi } from 'app/interfaces/usuarioLinkBi';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import handleErrorMessage from 'app/utils/error-message-handler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  usuario: Usuario
  links = [] as UsuarioLinkBi[];
  isTextVisible: boolean = false;
  

  constructor(
    _sessionStorageService: SessionStorageService, 
    private _usuarioHttpService: UsuarioHttpService,
    private _messageService: MessageService

  ) {
    this.usuario = JSON.parse(_sessionStorageService.getItem("user"));
  }

  /**
   * On init
   */
  ngOnInit() {
    this.getLinkBi();
  }


  getLinkBi() {    
    this._usuarioHttpService.getLinkBi(this.usuario.usuId).subscribe(
      {
        next: (result) => {          
          
          console.log(result);

          this.links = result;          
        },
        error: (error) => {
          handleErrorMessage(this._messageService, error);
        }
      }
    )
  }
  
  toggleTextVisibility() {
    this.isTextVisible = !this.isTextVisible;
  }

  get empresa() {
    return Empresa;
  }
}
