import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { FirstLetterPipe } from './first-letter.pipe';
import { TipoAuditoriaPipe } from './tipo-auditoria.pipe';
import { WithLoadingPipe } from './with-loading.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, FirstLetterPipe, TipoAuditoriaPipe, WithLoadingPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, FirstLetterPipe, TipoAuditoriaPipe, WithLoadingPipe]
})
export class CorePipesModule {}
