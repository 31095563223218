import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { PopoverDirective } from './popover.directive';
import { Overlay } from '@angular/cdk/overlay';
import { PopoverService } from '../popover.service';
import { FallbackImgDirective } from './fallback-img.directive';
import { AutofocusDirective } from './autofocus-directive';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';



@NgModule({
  declarations: [OnlyNumbersDirective, PopoverDirective, FallbackImgDirective, AutofocusDirective, BlockCopyPasteDirective],
  imports: [CommonModule],
  exports: [OnlyNumbersDirective, PopoverDirective, FallbackImgDirective, AutofocusDirective, BlockCopyPasteDirective],
  providers: [
    Overlay,
    PopoverService
  ]
})
export class DirectivesModule { }
