import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { Role } from 'app/interfaces/role';
import { TimezoneService } from 'app/utils/timezone.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoleHttpService {
  constructor(private _http: HttpClient, private _timezoneService: TimezoneService) {}

  /**
   * Função para buscar todas roles;
   * @param nome
   * @param page
   * @returns
   */
  getAll(nome = '', page = 0) {
    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/roles?nome=${nome}&page=${page}`);
  }

  /**
   * Função para buscar todas roles pelo nome;
   * @param nome
   * @param page
   * @returns
   */
  getAllByNome(nome: string = '') {
    return this._http.get<Role[]>(`${environment.apiUrl}/api/authentication/v1/roles/by-nome?nome=${nome}`);
  }

  /**
   * Função para buscar todas roles;
   * @param nome
   * @param page
   * @returns
   */
  getAllAux() {
    return this._http.get<Role[]>(`${environment.apiUrl}/api/authentication/v1/roles/all`);
  }

  /**
   * Função para buscar a role pelo id;
   * @param id
   * @returns
   */
  getById(id: number) {
    return this._http.get<Role>(`${environment.apiUrl}/api/authentication/v1/roles/${id}`);
  }

  /**
   * Função para salvar uma role;
   * @param role
   * @returns
   */
  salvar(role: Role) {
    return this._http.post<Role>(`${environment.apiUrl}/api/authentication/v1/roles`, role);
  }

  /**
   * Função para atualizar uma role;
   * @param role
   * @returns
   */
  update(role: Role) {
    return this._http.put<Role>(`${environment.apiUrl}/api/authentication/v1/roles/${role.rolId}`, role);
  }

  /**
   * Delete um registro;
   * @param id
   * @returns
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/authentication/v1/roles/${id}`);
  }

  getAllAudit(rolId: number, page: number, email: string, dataInicial: Date, dataFinal: Date, tipoAuditoria: string) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/roles-auditorias/${rolId}`, { params: params });
  }

  getAuditComparacao(rolId: number, rev: number) {
    return this._http.get<any[]>(`${environment.apiUrl}/api/authentication/v1/roles-auditorias/comparacao-audit/${rolId}?rev=${rev}`);
  }
}
