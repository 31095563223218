import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'DecodeTipoAuditoria'
})
export class TipoAuditoriaPipe implements PipeTransform {
  transform(revType: number): string {
    let tipoAuditoria = '';
    switch (revType) {
      case 0:
        tipoAuditoria = 'Adicionado';
        break;
      case 1:
        tipoAuditoria = 'Modificado';
        break;
      case 2:
        tipoAuditoria = 'Deletado';
        break;
      default:
        break;
    }
    return tipoAuditoria;
  }
}