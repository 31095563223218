import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { StatusRequisitoPipe } from './status-requisito.pipe';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { DescricaoTipoRequisitoPipe } from './tipo-requisito.pipe';
import { NormalizeWithNumbersDirective } from 'app/utils/pipes/normalize-text.pipe';
import { NormalizeWithoutNumbersDirective } from 'app/utils/pipes/normalize-text.pipe';
import { OnlyNumbersDirective } from 'app/utils/pipes/normalize-text.pipe';


@NgModule({
  declarations: [StatusRequisitoPipe, CustomCurrencyPipe, DescricaoTipoRequisitoPipe, 
    NormalizeWithNumbersDirective,
    OnlyNumbersDirective,
    NormalizeWithoutNumbersDirective],
  imports: [
    CommonModule
  ],
  exports: [
    StatusRequisitoPipe,
    CustomCurrencyPipe,
    DescricaoTipoRequisitoPipe,
    NormalizeWithNumbersDirective,
    OnlyNumbersDirective,
    NormalizeWithoutNumbersDirective,

  ],
  providers: [CurrencyPipe]
})
export class PipesModule { }
