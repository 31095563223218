import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Empresa } from 'app/interfaces/empresa';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { TimezoneService } from 'app/utils/timezone.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmpresaHttpService {
  constructor(private _http: HttpClient, private _timezoneService: TimezoneService) {}

  /**
   * Função para buscar todas empresas;
   * @param filtro
   * @param page
   * @returns
   */
  getAll({ filtro = '', page = 0 }: { filtro: string; page: number }) {
    let params = {
      filtro: filtro,
      page: page,
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/empresas`, { params: params });
  }

  /**
   * Função para buscar a empresa pelo id;
   * @param id
   * @returns
   */
  getById(id: number) {
    return this._http.get<Empresa>(`${environment.apiUrl}/api/authentication/v1/empresas/${id}`);
  }

  /**
   * Função para salvar uma empresa;
   * @param empresa
   * @returns
   */
  salvar(empresa: Empresa) {
    return this._http.post<Empresa>(`${environment.apiUrl}/api/authentication/v1/empresas`, empresa).toPromise();
  }

  /**
   * Função para atualizar uma empresa;
   * @param empresa
   * @returns
   */
  update(empresa: Empresa) {
    return this._http.put<Empresa>(`${environment.apiUrl}/api/authentication/v1/empresas/${empresa.empId}`, empresa).toPromise();
  }

  /**
   * Delete um registro;
   * @param id
   * @returns
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/authentication/v1/empresas/${id}`);
  }

  /**
   * Função só buscar somente empresas (livres de dependencias)
   * @returns
   */
  getEmpresas() {
    return this._http.get<Empresa[]>(`${environment.apiUrl}/api/authentication/v1/empresas/all`);
  }

  getAllAudit(empId: number, page: number, email: string, dataInicial: Date, dataFinal: Date, tipoAuditoria: string) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/empresas-auditorias/${empId}`, { params: params });
  }

  getAuditComparacao(empId: number, rev: number) {
    return this._http.get<any[]>(`${environment.apiUrl}/api/authentication/v1/empresas-auditorias/comparacao-audit/${empId}?rev=${rev}`);
  }
}
