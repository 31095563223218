import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/interfaces/Pageable/pageable';
import { Usuario } from 'app/interfaces/usuario';
import { UsuarioLinkBi } from 'app/interfaces/usuarioLinkBi';
import { TimezoneService } from 'app/utils/timezone.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsuarioHttpService {
  constructor(private _http: HttpClient, private _timezoneService: TimezoneService) {}

  /**
   * Função para buscar todos os usuários;
   * @returns
   */
  getUsuarios({ filtro = '', page = 0 }: { filtro: string; page: number }): Observable<Pageable> {
    let params = {
      filtro: filtro,
      page: page,
    };
    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/usuarios`, { params: params });
  }

  /**
   * Função para buscar todos os usuários;
   * @returns
   */
  getUsuariosLimitadoPorVisualizacaoGrupo(nome: string) {
    let params = {
      nome: nome,
    };
    return this._http.get<Usuario[]>(`${environment.apiUrl}/api/authentication/v1/usuarios/limitado-visualizacao-grupo/`, { params });
  }

  getAllUsuarios(nome = '', email = '', page = 0, size = 9999): Observable<Pageable> {
    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/usuarios?nome=${nome}&email=${email}&page=${page}&size=${size}`);
  }

  /**
   * Função para buscar o usuario pelo ID;
   * @param id
   * @returns
   */
  getUsuario(id: number) {
    return this._http.get<Usuario>(`${environment.apiUrl}/api/authentication/v1/usuarios/${id}`);
  }

  getLinkBi(id: number) {
    return this._http.get<UsuarioLinkBi[]>(`${environment.apiUrl}/api/authentication/v1/usuarios/getByUrlBi/${id}`);
  }

  /**
   * Função para buscar os usuários do modal de pesquisa;
   * @param id
   * @param nome
   * @param usuario
   * @param page
   * @returns
   */
  getUsuariosModal(id: number, nome = '', page = 1, size = 5) {
    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/usuarios/empresa?id=${id}&nome=${nome}&page=${page}&size=${size}`);
  }

  /**
   * Função para salvar um novo usuario;
   * @param usuario
   * @returns
   */
  salvar(usuario: Usuario) {
    return this._http.post<Usuario>(`${environment.apiUrl}/api/authentication/v1/usuarios`, usuario);
  }

  /**
   * Função para atualizar um usuario;
   * @param usuario
   * @returns
   */
  update(usuario: Usuario) {
    return this._http.put<Usuario>(`${environment.apiUrl}/api/authentication/v1/usuarios/${usuario.usuId}`, usuario);
  }

  /**
   * Delete um usuario;
   * @param id
   * @returns
   */
  delete(id: number) {
    return this._http.delete<boolean>(`${environment.apiUrl}/api/authentication/v1/usuarios/${id}`);
  }

  /**
   * Função para filtrar usuários por nome;
   * @param nome
   * @returns
   */
  getUsuarioByName(nome: string) {
    return this._http.get<Usuario[]>(`${environment.apiUrl}/api/authentication/v1/usuarios/find-by-name/${nome}`);
  }

  atualizaSenha(usuario: Usuario) {
    return this._http.post<Usuario>(`${environment.apiUrl}/api/authentication/v1/usuarios/atualiza-senha`, usuario);
  }

  getUsuariosAprovadores(parametro: String): Observable<Usuario[]> {
    return this._http.get<Usuario[]>(`${environment.apiUrl}/api/authentication/v1/usuarios/aprovadores?parametro=${parametro}`);
  }

  getUsuarioByRoleListAndByName(roleNameList: string[], name: string) {
    return this._http.get<Usuario[]>(
      `${environment.apiUrl}/api/authentication/v1/usuarios/find-by-role-list?roleNameList=${roleNameList}&name=${name}`
    );
  }

  getUsuarioNomeGrpById(usuId: number) {
    return this._http.get<Usuario>(`${environment.apiUrl}/api/authentication/v1/usuarios/find-usuario-nome-grupo/${usuId}`);
  }

  getAllAudit(usuId: number, page: number, email: string, dataInicial: Date, dataFinal: Date, tipoAuditoria: string) {
    let params = {
      size: 10,
      page: page,
      dataInicial: dataInicial ? this._timezoneService.utcToUserString(dataInicial) : '',
      dataFinal: dataFinal ? this._timezoneService.utcToUserString(dataFinal) : '',
      email: email ? email : '',
      tipoAuditoria: tipoAuditoria ? tipoAuditoria : '',
    };

    return this._http.get<Pageable>(`${environment.apiUrl}/api/authentication/v1/usuarios-auditorias/${usuId}`, { params: params });
  }

  getAuditComparacao(usuId: number, rev: number) {
    return this._http.get<any[]>(`${environment.apiUrl}/api/authentication/v1/usuarios-auditorias/comparacao-audit/${usuId}?rev=${rev}`);
  }
  /**
   * Função para filtrar usuários por nome;
   * @param nome
   * @returns
   */
  getUsuarioByNameCriacaoAtividade(nome: string) {
    return this._http.get<Usuario[]>(`${environment.apiUrl}/api/authentication/v1/usuarios/find-by-name-criacao-atividade/${nome}`);
  }
}
