import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { NgbdSortableHeader } from './table-sort/sort.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, NgbdSortableHeader],
  exports: [RippleEffectDirective, FeatherIconDirective, NgbdSortableHeader]
})
export class CoreDirectivesModule {}
