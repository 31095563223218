import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { Usuario } from './interfaces/usuario';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public preventAbuse = false;
  public progressSpinnerDlg = false;

  usuario$ = new BehaviorSubject<Usuario>({});

  constructor() {
  }

  setUsuarioSubject(usuario: Usuario) {
    this.usuario$.next(usuario);
  }

  getUsuarioSubject() {
    return this.usuario$.asObservable();
  }

  /**
   * 
   * @param title 
   * @param text 
   * @returns 
   */
  async fireSwalAsync(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncThreeButtons(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
      allowOutsideClick: false
    });

    return result.isConfirmed;
  }

  async fireSwalAsyncWarning(title: string, text: string, confirmButtonText: string): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      confirmButtonText: confirmButtonText,
      customClass: {
        confirmButton: 'btn swal-alert-custom-button',
        cancelButton: 'btn ml-1'
      },
    }
    )

    return result.isConfirmed;
  }

  getEmpresaBackground(nome: string): string {
    nome = nome.toUpperCase();
    if (nome.includes('LOGZ')) {
      return 'logz';
    } else if (nome.includes('SAFF')) {
      return 'saff';
    } else {
      return 'smx';
    }
  }
}
