import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'app/app.service';
import { Empresa } from 'app/interfaces/empresa';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { Grupo } from 'app/interfaces/grupo';
import { JasperRelatorioBusca } from 'app/interfaces/jasper/jasper-relatorio-busca';
import { Role } from 'app/interfaces/role';
import { Usuario } from 'app/interfaces/usuario';
import { UsuarioLinkBi } from 'app/interfaces/usuarioLinkBi';
import { EmpresaHttpService } from 'app/main/empresa/empresa-http.service';
import { GrupoHttpService } from 'app/main/grupo/grupo-http.service';
import { JasperHttpService } from 'app/main/relatorio/jasper-http.service';
import { RoleHttpService } from 'app/main/role/role-http.service';
import { UsuarioHttpService } from 'app/main/usuario/usuario-http.service';
import handleErrorMessage from 'app/utils/error-message-handler';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from 'rxjs';
import { UsuarioAuditComponent } from '../usuario-audit/usuario-audit.component';
import { UsuarioSenhaModalComponent } from '../usuario-senha-modal/usuario-senha-modal.component';
import { UsuarioLinkAddComponent } from './usuario-link-add/usuario-link-add.component';

@Component({
  selector: 'app-usuario-details',
  templateUrl: './usuario-details.component.html',
  styleUrls: ['./usuario-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsuarioDetailsComponent implements OnInit, OnDestroy {
  usuario: Usuario;
  form: FormGroup;
  subscription: Subscription;
  submitted = false;
  grupos = [] as Grupo[];
  empresas = [] as Empresa[];
  roles = [] as Role[];
  linksNovos = [] as UsuarioLinkBi[];
  rolesSelecionadas = [] as Role[];
  roleSubscription: Subscription;
  produtos = Produto;

  loadingRoles = true;

    // subscription
  _unsubscribeAll = new Subject<any>();

  jasperReportText = new Subject<string>();
  jasperRelatorioBusca = [] as JasperRelatorioBusca[];

  constructor(
    private _appService: AppService,
    private _router: Router,
    private _empresaHttpService: EmpresaHttpService,
    private _grupoHttpService: GrupoHttpService,
    private _usuarioHttpService: UsuarioHttpService,
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _modalService: NgbModal,
    private _roleHttpService: RoleHttpService,
    private _jasperHttpService: JasperHttpService
  ) { }

  ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : null;
  }

  ngOnInit(): void {
    this.initForm();
    this.getUsuario();
    this.getEmpresas();
    this.getGrupos();
    this.getRoles();
    this.subscribeFiltros();
  }

    subscribeFiltros() {

    this.jasperReportText.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._unsubscribeAll),
    )
      .subscribe(value => {
        if (value?.length >= 3) {
          this.findJasperByNome(value);
        }
      });
  }

  /**
   *
   */
  getEmpresas() {
    this._empresaHttpService.getEmpresas().subscribe({
      next: (result: Empresa[]) => {
        this.empresas = result;
      },
      error: error => {
        console.log(error);
        handleErrorMessage(this._messageService, error);
      },
    });
  }

  /**
   *
   */
  getGrupos() {
    this._grupoHttpService.getGrupos().subscribe({
      next: (result: Grupo[]) => {
        this.grupos = result;
      },
      error: error => {
        console.log(error);
        handleErrorMessage(this._messageService, error);
      },
    });
  }

  /**
   *
   */
  getRoles() {
    this._roleHttpService
      .getAllAux()
      .subscribe({
        next: (result: Role[]) => {
          if (this.usuario.usuId === 1) {
            this.roles = result;
          } else {
            this.roles = result.filter(r => r.nome !== 'MASTER');
          }
        },
        error: error => {
          console.log(error);
          handleErrorMessage(this._messageService, error);
        },
      })
      .add(() => {
        this.loadingRoles = false;
      });
  }

  /**
   * Função para iniciar o formulário de cadastro;
   */
  initForm() {
    this.form = new FormGroup({
      usuId: new FormControl(''),
      nome: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(200)])),
      ativo: new FormControl(true),
      usuarioGestorVisualizacao: new FormControl(false),
      visualizaGrupoMesmoNivel: new FormControl(false),
      produtos: new FormControl(null, Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(100)])),
      emailSenha: new FormControl('', Validators.compose([Validators.required])),
      grupo: new FormGroup({
        grpId: new FormControl('', Validators.compose([Validators.required])),
        nome: new FormControl(''),
      }),
      empresa: new FormGroup({
        empId: new FormControl('', Validators.compose([Validators.required])),
        nome: new FormControl(''),
      }),
      usuarioLinkBis: new FormControl([]),
      permissoesJasper: new FormControl([]),
    });
  }

  /**
   * Função para buscar o perfil do resolver;
   */
  getUsuario() {
    // get data from resolver;
    this._activatedRoute.data.subscribe(result => {
      this.usuario = result.usuario;

      this.form.patchValue(this.usuario);
      this.rolesSelecionadas = this.usuario.roles;
    });
  }

  /**
   *
   * @param obj1
   * @param obj2
   * @returns
   */
  compareEmpresa(obj1: Empresa, obj2: Empresa) {
    let b: boolean;
    b = obj1 && obj2 ? obj1 === obj2 : obj1.empId === obj2.empId;
    return b;
  }

  /**
   *
   * @param obj1
   * @param obj2
   * @returns
   */
  compareGrupo(obj1: Grupo, obj2: Grupo) {
    let b: boolean;
    b = obj1 === obj2;
    return b;
  }

  /**
   * Função para gravar novo registro;
   */
  async salvar() {
    // seta o formulario como submitted;
    this.submitted = true;

    // verifica se todas informações foram preenchidas corretamente;
    if (this.form.valid && this.rolesSelecionadas.length > 0) {
      // pergunta se quer realmente salvar;
      let confirmed = await this._appService.fireSwalAsync('Atenção!', 'Deseja realmente gravar um novo registro?', 'Salvar');

      if (confirmed) {
        // mostra o progress bar;
        this._appService.progressSpinnerDlg = true;

        try {
          // pega os valores do form;
          this.usuario = this.form.value;
          this.usuario.roles = this.rolesSelecionadas;

          // chama api para salvar o usuário;
          this.usuario = await this._usuarioHttpService.update(this.usuario).toPromise();

          // se tudo deu certo, mostra mensagem de success;
          this._messageService.success('Atenção', 'Registro salvo com sucesso.');

          // reinicia as variaveis da tela;
          this.form.markAsPristine();
          this.submitted = false;
        } catch (error) {
          console.info(error);
          handleErrorMessage(this._messageService, error);
        }

        // esconde o progress bar;
        this._appService.progressSpinnerDlg = false;
      }
    } else {
      this._messageService.warning('Atenção', 'Preencha todos os campos.');
    }
  }

  /**
   * Voltar para tela anterior;
   */
  async voltar() {
    if (this.form.dirty) {
      let confirmed = await this._appService.fireSwalAsync('Deseja Realmente voltar?', 'Você perderá as alterações feitas até agora.', 'Voltar');
      if (confirmed) {
        this._router.navigateByUrl('/usuario', {
          state: {
            skipResolver: true,
          },
        });
      }
    } else {
      this._router.navigateByUrl('/usuario', {
        state: {
          skipResolver: true,
        },
      });
    }
  }

  modalSenhaUsuario() {
    const modalRef = this._modalService.open(UsuarioSenhaModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.usuario = this.usuario;

    modalRef.result.then(() => { }).catch(() => { });
  }

  /**
   * Função para marcar todas roles;
   */
  selecionarTodasRoles() {
    this.rolesSelecionadas = this.roles.filter(x => x.rolId);
  }

  /**
   * Função para desmarcar todas roles;
   */
  desmarcarTodasRoles() {
    this.rolesSelecionadas = [];
  }

  comparaRoles(obj1, obj2) {
    let b: boolean;
    b = obj1 && obj2 ? obj1.rolId === obj2.rolId : obj1 === obj2;
    return b;
  }

  editarLink(usuarioLinkBi, index) {
    const modalRef = this._modalService.open(UsuarioLinkAddComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm',
    });
    modalRef.componentInstance.usuarioLinkBi = usuarioLinkBi;

    modalRef.result.then(result => {
      if (result) {
        var actualValue = this.form.controls.usuarioLinkBis.value;
        actualValue[index] = result;
        this.form.controls.usuarioLinkBis.setValue(actualValue);
        //this.form.markAsDirty();
      }
    });
  }

  async deletarLink(index: number) {
    let confirmation = await this._appService.fireSwalAsync('Atenção', 'Deseja Realmente deletar?', 'Deletar');
    if (confirmation) {
      var actualValue = this.form.controls.usuarioLinkBis.value;
      actualValue.splice(index, 1);
      this.form.controls.usuarioLinkBis.setValue(actualValue);
      //this.form.markAsDirty();
    }
  }

  adicionarLink() {
    const modalRef = this._modalService.open(UsuarioLinkAddComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm',
    });

    modalRef.result.then(result => {
      if (result) {
        var actualValue = this.form.controls.usuarioLinkBis.value;
        actualValue.push(result);
        this.form.controls.usuarioLinkBis.setValue(actualValue);
      }
    });
  }

  abrirModalAuditoria() {
    const modalRef = this._modalService.open(UsuarioAuditComponent, {
      centered: true,
      backdrop: true,
      size: 'xl',
    });
    modalRef.componentInstance.usuarios = this.usuario;
  }


  /**
  * 
  * @param nome 
  */
  findJasperByNome(nome: string) {
    this._jasperHttpService.findByNome(nome).subscribe({
      next: (result) => {
        this.jasperRelatorioBusca = result?.resourceLookup;
      },
      error: (err) => {
        console.log(err);
        handleErrorMessage(this._messageService, err);
      }
    })
  }

  get f() {
    return this.form.controls;
  }
}
