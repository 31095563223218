import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoSelectComponent } from './produto-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProdutoSelectComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ProdutoSelectComponent
  ],
  providers: [FormGroupDirective]
})
export class ProdutoSelectModule { }
