import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'customCurrency'
  })
  export class CustomCurrencyPipe implements PipeTransform {
  
    constructor(private currencyPipe: CurrencyPipe) {}
  
    transform(value: number, currencyCode: string = 'BRL', display: string | boolean = 'symbol', digitsInfo: string = '1.2-2', locale: string = 'pt-BR'): string | null {
      if (value === null || value === 0) {
        return '';
      }
  
      return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    }
  
  }