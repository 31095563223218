import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-iframe',
  templateUrl: './home-iframe.component.html',
  styleUrls: ['./home-iframe.component.scss']
})



export class HomeIframeComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer) { 
    }
  @Input() link;
  ngOnInit(): void {

  }

  getUrl() {
    
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.link.link);
  }

}


