import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'app/app.service';
import { Usuario } from 'app/interfaces/usuario';
import handleErrorMessage from 'app/utils/error-message-handler';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { UsuarioHttpService } from '../usuario-http.service';

@Component({
  selector: 'app-usuario-senha-modal',
  templateUrl: './usuario-senha-modal.component.html',
  styleUrls: ['./usuario-senha-modal.component.scss']
})
export class UsuarioSenhaModalComponent implements OnInit {

  @Input() usuario: Usuario;

  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public formReset: UntypedFormGroup;
  public submitted = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private _appService: AppService,
    private _modalService: NgbModal,
    private _messageService: MessageService,
    private _formBuilder: FormBuilder,
    private _usuarioHttpService: UsuarioHttpService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * 
   */
  async submit() {
    this.submitted = true;

    let usuario = { usuId: this.usuario.usuId, senha: this.f.newPassword.value }
    let confirmation = await this._appService.fireSwalAsync('Atenção', 'Deseja Realmente Alterar?', 'Alterar');

    if (confirmation) {
      this._usuarioHttpService.atualizaSenha(usuario).subscribe({
        next: (result) => {
          this._messageService.success('Atenção', 'Senha Atualizada com sucesso!');
          this.close();
        },
        error: (err) => {
          console.log(err);
          handleErrorMessage(this._messageService, err);
        }
      })
    }
  }

  close() {
    this._activeModal.close();
  }

  /**
   * 
   */
  initForm() {
    this.formReset = this._formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^#(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{11,}$')]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^#(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{11,}$')]]
    }, {
      validators: [this.requireConfirmPassword]
    });
  }

  /**
   * Validator custom para verificar se a senha e a confirmação batem;
   * @param form 
   * @returns 
   */
  requireConfirmPassword(form: FormGroup) {
    if (form.get('newPassword').value !== form.get('confirmPassword').value) {
      return { requireConfirmPassword: true };
    }
    return null;
  }

  get f() {
    return this.formReset.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }
}
