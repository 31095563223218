import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-headers',
  templateUrl: './screen-headers.component.html',
  styleUrls: ['./screen-headers.component.scss']
})
export class ScreenHeadersComponent implements OnInit {

  @Input() titulo: string;
  @Input() descricao: string;

  constructor() { }

  ngOnInit(): void {
  }

}
