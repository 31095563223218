import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor() { }

  getIcon(tipo: string) {
    let tipoL = tipo.toLowerCase();
    return `assets/images/icons/file-icons/${tipoL}.png`
  }
}
