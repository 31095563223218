import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { Empresa } from 'app/interfaces/empresa';
import { Produto } from 'app/interfaces/enum/produto.enum';
import { Grupo } from 'app/interfaces/grupo';
import { JasperRelatorioBusca } from 'app/interfaces/jasper/jasper-relatorio-busca';
import { Role } from 'app/interfaces/role';
import { Usuario } from 'app/interfaces/usuario';
import { EmpresaHttpService } from 'app/main/empresa/empresa-http.service';
import { GrupoHttpService } from 'app/main/grupo/grupo-http.service';
import { JasperHttpService } from 'app/main/relatorio/jasper-http.service';
import { RoleHttpService } from 'app/main/role/role-http.service';
import { EmailHttpService } from 'app/main/shared/services/email-http.service';
import { UsuarioHttpService } from 'app/main/usuario/usuario-http.service';
import handleErrorMessage from 'app/utils/error-message-handler';
import { SessionStorageService } from 'app/utils/session-storage.service';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-usuario-add',
  templateUrl: './usuario-add.component.html',
  styleUrls: ['./usuario-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsuarioAddComponent implements OnInit, OnDestroy {

  usuario: Usuario;
  form: FormGroup;
  subscription: Subscription;
  submitted = false;
  grupos = [] as Grupo[];
  empresas = [] as Empresa[];
  usuarioLogado: Usuario;
  roles = [] as Role[];
  rolesSelecionadas = [] as Role[];
  roleSubscription: Subscription;
  loadingRoles = true;
  produtos = Produto;

  // subscription
  _unsubscribeAll = new Subject<any>();

  jasperReportText = new Subject<string>();
  jasperRelatorioBusca = [] as JasperRelatorioBusca[];

  constructor(
    private _appService: AppService,
    private _router: Router,
    private _empresaHttpService: EmpresaHttpService,
    private _grupoHttpService: GrupoHttpService,
    private _usuarioHttpService: UsuarioHttpService,
    private _messageService: MessageService,
    private _sessionStorageService: SessionStorageService,
    private _emailHttpService: EmailHttpService,
    private _roleHttpService: RoleHttpService,
    private _jasperHttpService: JasperHttpService
  ) {
    this.usuarioLogado = JSON.parse(this._sessionStorageService.getItem("user"));
  }

  ngOnDestroy(): void {
    this.subscription ? this.subscription.unsubscribe() : null;
    this.roleSubscription ? this.roleSubscription.unsubscribe() : null;
  }

  ngOnInit(): void {
    this.initForm();
    this.getEmpresas();
    this.getGrupos();
    this.getRoles();
    this.subscribeFiltros();
  }

  subscribeFiltros() {

    this.jasperReportText.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this._unsubscribeAll),
    )
      .subscribe(value => {
        if (value?.length >= 3) {
          this.findJasperByNome(value);
        }
      });
  }

  getEmpresas() {
    this._empresaHttpService.getEmpresas().subscribe({
      next: (result: Empresa[]) => {
        this.empresas = result;
      },
      error: (error) => {
        console.log(error);
        handleErrorMessage(this._messageService, error)
      }
    })
  }

  getRoles() {
    this._roleHttpService.getAllAux().subscribe({
      next: (result: Role[]) => {
        this.roles = result.filter(r => r.nome !== 'MASTER');
      },
      error: (error) => {
        console.log(error);
        handleErrorMessage(this._messageService, error)
      }
    }).add(() => {
      this.loadingRoles = false;
    })
  }

  getGrupos() {
    this._grupoHttpService.getGrupos().subscribe({
      next: (result: Grupo[]) => {
        this.grupos = result;
      },
      error: (error) => {
        console.log(error);
        handleErrorMessage(this._messageService, error)
      }
    })
  }

  /**
   * Função para iniciar o formulário de cadastro;
   */
  initForm() {
    this.form = new FormGroup({
      usuId: new FormControl(''),
      nome: new FormControl('', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(200)])),
      ativo: new FormControl(true),
      usuarioGestorVisualizacao: new FormControl(false),
      visualizaGrupoMesmoNivel: new FormControl(false),
      produtos: new FormControl(null, Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(100)])),
      emailSenha: new FormControl('', Validators.compose([Validators.required])),
      grupo: new FormGroup({
        grpId: new FormControl('', Validators.compose([Validators.required])),
        nome: new FormControl('')
      }),
      empresa: new FormGroup({
        empId: new FormControl('', Validators.compose([Validators.required])),
        nome: new FormControl(''),
      }),
      permissoesJasper: new FormControl([]),
    });
  }

  /**
   * 
   * @param obj1 
   * @param obj2 
   * @returns 
   */
  compareEmpresa(obj1: any, obj2: any) {
    let b: boolean;
    b = obj1 && obj2 ? (obj1.empId === obj2.empId) : obj1 === obj2;
    return b;
  }

  compareGrupo(obj1: any, obj2: any) {
    let b: boolean;
    b = obj1 && obj2 ? (obj1.grpId === obj2.grpId) : obj1 === obj2;
    return b;
  }

  /**
   * Função para gravar novo registro;
   */
  async salvar() {

    // seta o formulario como submitted;
    this.submitted = true;

    // verifica se todas informações foram preenchidas corretamente;
    if (this.form.valid && this.rolesSelecionadas.length > 0) {

      // pergunta se quer realmente salvar;
      let confirmed = await this._appService.fireSwalAsync('Atenção!', 'Deseja realmente gravar um novo registro?', 'Salvar');

      if (confirmed) {

        // mostra o progress bar;
        this._appService.progressSpinnerDlg = true;

        try {

          // pega os valores do form;
          this.usuario = this.form.value;
          this.usuario.roles = this.rolesSelecionadas;

          // chama api para salvar o usuário;
          this.usuario = await this._usuarioHttpService.salvar(this.usuario).toPromise();

          // se tudo deu certo, mostra mensagem de success;
          this._messageService.success('Atenção', 'Registro salvo com sucesso.');
          this.criaUsuarioSenha(this.usuario.email);
          this._router.navigateByUrl('/usuario');

        } catch (error) {
          console.info(error)
          handleErrorMessage(this._messageService, error);
        }

        // esconde o progress bar;
        this._appService.progressSpinnerDlg = false;

      }

    } else {
      this._messageService.warning('Atenção', 'Preencha todos os campos.');
    }
  }

  criaUsuarioSenha(email: string) {
    this._emailHttpService.resetSenha(email).subscribe({
      next: (result) => {
      }
    })
  }

  /**
   * Voltar para tela anterior;
   */
  async voltar() {
    if (this.form.dirty) {
      let confirmed = await this._appService.fireSwalAsync("Deseja Realmente voltar?", "Você perderá as alterações feitas até agora.", 'Voltar');
      if (confirmed) {
        this._router.navigateByUrl('/usuario');
      }
    } else {
      this._router.navigateByUrl('/usuario');
    }
  }

  /**
 * Função para marcar todas roles;
 */
  selecionarTodasRoles() {
    this.rolesSelecionadas = this.roles.filter(x => x.rolId);
  }

  /**
   * Função para desmarcar todas roles;
   */
  desmarcarTodasRoles() {
    this.rolesSelecionadas = [];
  }

  /**
  * 
  * @param nome 
  */
  findJasperByNome(nome: string) {
    this._jasperHttpService.findByNome(nome).subscribe({
      next: (result) => {
        this.jasperRelatorioBusca = result?.resourceLookup;
      },
      error: (err) => {
        console.log(err);
        handleErrorMessage(this._messageService, err);
      }
    })
  }
}
