import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenHeadersComponent } from './screen-headers.component';



@NgModule({
  declarations: [ScreenHeadersComponent],
  imports: [
    CommonModule,    
  ],
  exports:[
    ScreenHeadersComponent
  ]
})
export class ScreenHeadersModule { }
