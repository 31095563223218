import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app.service';
import { Usuario } from 'app/interfaces/usuario';
import { UsuarioHttpService } from 'app/main/usuario/usuario-http.service';
import handleErrorMessage from 'app/utils/error-message-handler';
import { MessageService } from 'app/utils/toastr-custom/message.service';
import { Subscription } from 'rxjs';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.scss']
})
export class UsuarioListComponent implements OnInit {

  usuarios = [] as Usuario[];
  subscription: Subscription;
  public pageIndex: number;

  constructor(
    private _appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public usuarioService: UsuarioService,
    private _usuarioHttpService: UsuarioHttpService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getUsuarios();
  }

  /**
   * Função para buscar todos usuários;
   */
  getUsuarios() {
    // get data from resolver;
    this.subscription = this._activatedRoute.data.subscribe(
      (result) => {
        this.usuarioService.state.data = result.usuarios;
        this.pageIndex = this.usuarioService.state.data.number + 1;
        this.usuarios = Object.assign([], this.usuarioService.state.data.content);
      }
    )
  }

  /**
   * Função para filtrar a table;
   */
  filtrar(page = 1) {

    this._appService.progressSpinnerDlg = true;

    this._usuarioHttpService.getUsuarios({
      filtro: this.usuarioService.state.filtro,
      page: page - 1
    }).subscribe(
        (result) => {
          this.usuarioService.state.data = result;
          this.pageIndex = this.usuarioService.state.data.number + 1;
        }
      ).add(() => {
        this.usuarios = Object.assign([], this.usuarioService.state.data.content);

        this._appService.progressSpinnerDlg = false;
      });
  }


  /**
  * Função para buscar por paginação;
  * @param event 
  */
  pageChanged(event: any) {
    this._appService.progressSpinnerDlg = true;

    this._usuarioHttpService.getUsuarios({
      filtro: this.usuarioService.state.filtro,
      page: event - 1
    }).subscribe(
      (result) => {
        this.usuarioService.state.data = result;
        this.pageIndex = this.usuarioService.state.data.number + 1;
      },
      error => {
        console.log(error);
      }
    ).add(() => {
      this.usuarios = Object.assign([], this.usuarioService.state.data.content);
      this._appService.progressSpinnerDlg = false;
    });

  }

  limparFiltro() {
    this.usuarioService.state.filtro = "";
    this.filtrar();
  }

  get filtro() { 
    return this.usuarioService.state.filtro
  }

  set filtro(value: string) {
    this.usuarioService.state.filtro = value;
  }


  /**
   * Função para deletar um usuario
   * @param id 
   */
  async deletar(usuario: Usuario) {
    let confirmed = await this._appService.fireSwalAsync("Atenção!", `Deseja realmente deletar o usuário?`, 'Deletar');

    if (confirmed) {

      this._appService.progressSpinnerDlg = true;

      this._usuarioHttpService.delete(usuario.usuId).subscribe(
        (result) => {
          this._messageService.success('Atenção', 'Registro deletado com sucesso.');
        },
        error => {
          console.info(error);
          handleErrorMessage(this._messageService, error);
        }
      ).add(() => {
        this.filtrar(this.usuarioService.state.data.number + 1);
        this._appService.progressSpinnerDlg = false;
      });
    }
  }
}
