import { AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'app/app.service';
import { Farol } from 'app/interfaces/enum/farol.enum';
import { ParceiroNegocioFarolMetas } from 'app/interfaces/parceiro-negocio-farol-metas';
import { Usuario } from 'app/interfaces/usuario';
import { AuthService } from 'app/main/pages/authentication/auth.service';
import { ParceiroHttpService } from 'app/main/parceiro/parceiro-http.service';
import { UsuarioSenhaModalComponent } from 'app/main/usuario/usuario-senha-modal/usuario-senha-modal.component';
import handleErrorMessage from 'app/utils/error-message-handler';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  public qtdClients: any;
  public imagemFarol: string;
  public farolMetas: ParceiroNegocioFarolMetas;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;

  usuarioLogado: Usuario;

  public isVendedor = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    public _translateService: TranslateService,
    private _appService: AppService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _parceiroHttpService: ParceiroHttpService,
  ) {    

    
    this.languageOptions = {
      pt: {
        title: 'Português',
        flag: 'br'
      },
      en: {
        title: 'English',
        flag: 'us'
      }
      
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
  
  ngAfterViewInit(): void {
    this.verificaVendedor();
  }

  verificaVendedor() {
    this.isVendedor = this._authService.getUserRole().some(r => r === "PER_VENDEDOR" || r === "PER_VENDEDOR_IS");  
    if(this.isVendedor){
      this.getDataQtdClientsOfSales();
      this.getFarolMetas();
    }  
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  async logout() {

    let confirmed = await this._appService.fireSwalAsync('Atenção?', 'Deseja Realmente Sair? ', 'Sair');

    if (confirmed) {
      sessionStorage.clear();
      this._router.navigate(['/auth/login']);
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._appService.getUsuarioSubject().subscribe(
      usuario => {
        this.usuarioLogado = usuario;  
      }
    );

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });
    
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  modalSenhaUsuario() {

    const modalRef = this._modalService.open(UsuarioSenhaModalComponent, {
      centered: true,
      backdrop: 'static'
    });

    modalRef.componentInstance.usuario = this.usuarioLogado;

    modalRef.result.then(() => {
      
    }).catch(() => { });
  }

  getDataQtdClientsOfSales() {
    this._parceiroHttpService.getQtdClientsbySalesId().subscribe({
      next: (r) => {
        this.qtdClients = r;
      },
      error: (e) => {
        handleErrorMessage(e, e)
      }
    })
  }

  getFarolMetas(){
    let parceiroId = this._authService.getParceiroNegocioId();
    this._parceiroHttpService.getLastFarolMetasByParceiroNegocioId(parceiroId).subscribe({
      next: (r) => {
        this.farolMetas = r;

        if(r != null){
          switch(this.farolMetas.farol){
            case Farol.VERDE: this.imagemFarol = "../../../../assets/images/icons/cartao_verde.png";
            break;
            case Farol.AMARELO: this.imagemFarol = "../../../../assets/images/icons/cartao_amarelo.png";
            break;
            case Farol.VERMELHO: this.imagemFarol = "../../../../assets/images/icons/cartao_vermelho.png";
            break;
            case Farol.ROXO: this.imagemFarol = "../../../../assets/images/icons/cartao_roxo.png";
            break;
          }
        } else {
          this.imagemFarol = "../../../../assets/images/icons/cartao_verde.png";
        } 
      },
      error: (e) => {
        handleErrorMessage(e, e)
      }
    })
  }
}
