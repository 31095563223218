import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { Usuario } from 'app/interfaces/usuario';
import { AuthService } from 'app/main/pages/authentication/auth.service';
import { SessionStorageService } from 'app/utils/session-storage.service';



@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  menu_usuario = [];
  usuarioLogado = {} as Usuario;

  /**
   *
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(
    private _coreMenuService: CoreMenuService,
    private _sessionStorageService: SessionStorageService,
    private _authService: AuthService) {
    this.menu_usuario = this._authService.getTokenRecursos();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
  }


  // Função para habilitar os menus de acordo com os acessos do usuário;
  habitaliMenus(menu: any) { 
    if (menu.id === 'menu-dashboard') {
      return true;
    } else {
      return this.menu_usuario?.find(x => x === menu.id);
    }
  }

  desabilitaMenuComSubMenu(menu: any) {
    let exibeMenu = false;
    menu.children?.forEach(m => {
      if (!exibeMenu) {
        exibeMenu = !!this.menu_usuario?.find(x => x === m.id);
      }
    });

    return exibeMenu;
  }
}
