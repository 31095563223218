import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomBreakPointsProvider } from 'app/layout/custom-breakpoints';
import { HorizontalLayoutModule } from 'app/layout/horizontal/horizontal-layout.module';
import { VerticalLayoutModule } from 'app/layout/vertical/vertical-layout.module';
import { DirectivesModule } from 'app/utils/directives/directives.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    VerticalLayoutModule,
    HorizontalLayoutModule,
    FormsModule,
    DirectivesModule,
    CoreCommonModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    CorePipesModule,
  ],
  providers: [CustomBreakPointsProvider],
  exports: [
    VerticalLayoutModule,
    HorizontalLayoutModule,

  ],
  declarations: [

  ]
})
export class LayoutModule { }
